import { Container } from "react-bootstrap";
import "./fullWidthImage.scss";

export const FullWidthImage = (props) => {
  return (
    <Container className="pp-full-width-image">
      <img src={props.imagePath} alt="" />
    </Container>
  );
};
