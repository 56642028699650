export const CTAs = (props) => {
  return (
    <div onClick={props.callBack}>
      {!!props.cta.type && props.cta.type === "video" && (
        <div className={"pp-btn btn-video-cta"}>{props.cta.text}</div>
      )}
      {!!props.cta.type && props.cta.type === "text" && (
        <div className={`pp-btn ${props.cta.classes}`}>{props.cta.text}</div>
      )}
    </div>
  );
};
