import { isRetina } from "../utils/retina";
import { useEffect, useState } from "react";

export const PPImage = (props) => {
  const [src, setSrc] = useState(props.src);

  useEffect(() => {
    if (props.hasRetina && props.type !== "svg") {
      setSrc(
        isRetina() ? `${src}@2x.${props.type}` : `${props.src}.${props.type}`
      );
    } else {
      setSrc(props.src + "." + props.type);
    }
  }, [props, src]);

  return (
    <img
      className={`pp-image ${props.className}`}
      src={src}
      alt={props.alt ? props.alt : ""}
    />
  );
};
