import "./modal.scss";
import { PPButton } from "../components/button";
import { useEffect, useState } from "react";
import axios from "axios";
import { useRef } from "react";
import "./video.scss";

const Video = () => {
  const videoRef = useRef();

  return (
    <div className="pp-video" ref={videoRef}>
      <div className="iframe-wrapper">
        <iframe
          className="video-iframe"
          frameBorder="0"
          allowFullScreen
          title="penpal video"
          src="https://www.youtube.com/embed/uuUFxhOvsVQ?mute=1&controls=1&modestbranding=1&loop=1&playlist=uuUFxhOvsVQ"
        >
          &nbsp;
        </iframe>
      </div>
    </div>
  );
};

const SignUpForm = (props) => {
  const [email, setEmail] = useState("");
  const [warning, setWarning] = useState("");

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return !(!email || regex.test(email) === false);
  };

  const confirmView = () => {
    axios
      .post("https://api.penpal.tech/v1/register", {
        email: email,
        registeredFrom: props.type,
      })
      .then((res) => {
        props.setConfirmed();

        const timeout = setTimeout(() => {
          props.disableModal();
          setWarning("");
          clearTimeout(timeout);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="pp-modal-content">
        <div className="text">
          Penpal is in beta testing phase. <br />
          Do you wanna be a part of it ? <br />
          Or just want to get updated ?
        </div>
        <div className="form">
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
          />
          <PPButton
            className="sign-up mid"
            text="Sign Up"
            onClick={() => {
              emailValidation() ? confirmView() : setWarning("Email not vaild");
            }}
          />
        </div>
        <div className="warning">{warning}</div>
      </div>
    </>
  );
};

export const PPModal = (props) => {
  const [confirmed, setConfirmed] = useState(false);

  const confirmForm = () => {
    setConfirmed(true);
    console.log("confirmed", props.type);
  };

  const modalBodyClick = (e) => {
    const isOuterClicked = e.target.classList.contains("pp-modal");
    isOuterClicked && props.disableModal();
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      e.keyCode === 27 && props.disableModal();
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [props]);

  return (
    <div
      className={"pp-modal"}
      onClick={(e) => {
        modalBodyClick(e);
      }}
    >
      <div className={`pp-modal-body ${props.type}`}>
        <div className="d-md-none modal-close">
          <span className="close-button" onClick={props.disableModal}>
            <img src="./images/modal/close.svg" alt="Close modal" />
          </span>
        </div>

        {props.type === "video" && <Video />}

        {props.type === "header" && !confirmed && (
          <SignUpForm type={props.type} setConfirmed={confirmForm} />
        )}

        {props.type === "header" && confirmed && (
          <div className="pp-modal-content confirmed">
            <div className="text">Gotcha</div>
          </div>
        )}
      </div>
    </div>
  );
};
