import { Col, Container, Row } from "react-bootstrap";
import { PPImage } from "../components/image";
import "./textImage.scss";

export const TextImage = (props) => {
  let direction = props.direction === "rtl" ? "reverse" : "normal";
  let styleOverflow = props.overflow
    ? {
        top: props.overflow.top,
        height: `calc(100% ${props.overflow.height < 0 ? "+" : "-"} ${Math.abs(
          props.overflow.height
        )}px)`,
      }
    : {};

  return (
    <div className={`pp-text-image ${props.colorTheme}`} id={props.id}>
      <Container>
        <Row
          className={`pp-text-image-row direction-${direction} d-flex align-items-center`}
        >
          <Col xs={12} md={6} style={{}}>
            <PPImage
              className="text-image-image d-block w-100"
              type={"png"}
              src={props.imagePath}
            />
          </Col>
          <Col xs={12} md={6}>
            <div className="text-col">
              {props.highlight && (
                <div className="highlight">{props.highlight}</div>
              )}
              <h2 className="title">{props.title}</h2>
              <div className="body  color-grey">{props.body}</div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="bg-overflow" style={styleOverflow}>
        &nbsp;
      </div>
    </div>
  );
};
