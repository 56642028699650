import { Container, Row, Col } from "react-bootstrap";
import "./header.scss";
import { PPImage } from "../components/image";

export const Header = (props) => {
  return (
    <Container className="header-container">
      <Row className="header-row">
        <Col className="d-flex">
          <button className="d-flex align-items-center">
            <PPImage
              src="./images/penpal_logo"
              className="pp-logo"
              type="svg"
              hasRetina
            />
            <div className="pp-logo-text">PENPAL</div>
          </button>
          <div className="d-flex buttons w-100 justify-content-end align-items-center">
            <a className="why-penpal" href="#withPenpal">
              Why PenPal
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
