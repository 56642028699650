import { Container } from "react-bootstrap";
import "./textOnImage.scss";

export const TextOnImage = (props) => {
  return (
    <div className="pp-text-on-image">
      <Container
        style={{
          backgroundImage: `url('./images/sections/text-on-image/ruler.jpg')`,
        }}
      >
        <div className="text-col h-100">
          <div className="tagline">With Penpal</div>
          <div className="title">Students learn on their own, anytime</div>
          <div className="copy">
            Penpal makes independent learning accessible, intuitive, and fun.
            Picking up the pen from the connected dock easily brings students
            and educators one step closer together.{" "}
          </div>
        </div>
      </Container>
    </div>
  );
};
