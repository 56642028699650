import {
  useAccordionButton,
  Accordion,
  Col,
  Container,
  Row,
} from "react-bootstrap";

import "./faq.scss";

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log());

  return (
    <div className="pp-accordion-header" onClick={decoratedOnClick}>
      {children}
    </div>
  );
};

export const FAQ = () => {
  return (
    <div className="pp-faq">
      <Container>
        <Row className="title-row">
          <Col>
            <h2>FAQ</h2>
          </Col>
        </Row>

        <Row className="accordion-row">
          <Accordion>
            <Col>
              <CustomToggle eventKey="99">
                How does the dock connects?
              </CustomToggle>
              <div className="d-none">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusamus autem dolores, eum ex nulla provident quasi
                  voluptatibus. Atque debitis, dicta, dolores modi molestias
                  natus, perspiciatis quasi qui quis quisquam voluptas?
                </p>
              </div>
            </Col>

            <Col>
              <CustomToggle eventKey="99">
                How much cost a yearly subscription after the first year?
              </CustomToggle>
              <div className="d-none">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusantium cupiditate debitis eveniet labore possimus quia
                  reprehenderit sequi sit vitae. Architecto ducimus eaque enim
                  eos impedit placeat quas rerum similique tempora?
                </p>
              </div>
            </Col>

            <Col>
              <CustomToggle eventKey="99">
                What do I get in the Premium Pack ?
              </CustomToggle>
              <div className="d-none">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusantium cupiditate debitis eveniet labore possimus quia
                  reprehenderit sequi sit vitae. Architecto ducimus eaque enim
                  eos impedit placeat quas rerum similique tempora?
                </p>
              </div>
            </Col>

            <Col>
              <CustomToggle eventKey="99">Who endorsed Penpal?</CustomToggle>
              <div className="d-none">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusantium cupiditate debitis eveniet labore possimus quia
                  reprehenderit sequi sit vitae. Architecto ducimus eaque enim
                  eos impedit placeat quas rerum similique tempora?
                </p>
              </div>
            </Col>
          </Accordion>
        </Row>
      </Container>
    </div>
  );
};
