import { Col, Container, Row } from "react-bootstrap";

import "./whyPenpal.scss";

export const WhyPenpal = () => {
  return (
    <div className="pp-why-penpal">
      <Container>
        <div
          className="overflow-image"
          style={{
            backgroundImage: `url("./images/sections/whypenpal/hand-drawing.png")`,
          }}
        >
          &nbsp;
        </div>

        <Row className="title-row">
          <Col className="text-center">
            <h2>Why Penpal ? A comparison</h2>
          </Col>
        </Row>

        <Row className="vs-row">
          <Col className="text-col left">
            Digital <br /> Tablets
          </Col>
          <Col className="separator p-0">
            <img src="./images/sections/whypenpal/vs-separator.png" alt="" />
          </Col>
          <Col className="text-col right">
            Penpal <br /> (Dock&Tablet)
          </Col>
        </Row>

        <Row className="box-title-row">
          <Col>
            <h2>Display</h2>
          </Col>
        </Row>

        <Row className="boxes-row">
          <Col className="box" xs={6}>
            Bright
          </Col>
          <Col className="box" xs={6}>
            Usable I bright environment too
          </Col>
          <Col className="box" xs={6}>
            Causes eye fatigue
          </Col>
          <Col className="box" xs={6}>
            Eye Friendly
          </Col>
        </Row>

        <Row className="box-title-row">
          <Col>
            <h2>Functionality</h2>
          </Col>
        </Row>

        <Row className="boxes-row">
          <Col className="box" xs={6}>
            Access to games
          </Col>
          <Col className="box" xs={6}>
            Access to classes, exercises and tips
          </Col>
          <Col className="box" xs={6}>
            -
          </Col>
          <Col className="box" xs={6}>
            Access to a teacher
          </Col>
          <Col className="box" xs={6}>
            -
          </Col>
          <Col className="box" xs={6}>
            All learning books and learnings
          </Col>
        </Row>

        <Row className="box-title-row">
          <Col>
            <h2>Privacy</h2>
          </Col>
        </Row>

        <Row className="boxes-row">
          <Col className="box" xs={6}>
            HD Camera
          </Col>
          <Col className="box" xs={6}>
            Webcam free - No intrusion
          </Col>
          <Col className="box" xs={6}>
            Ad Tracking
          </Col>
          <Col className="box" xs={6}>
            No tracking
          </Col>
        </Row>
      </Container>
    </div>
  );
};
