import { Col, Container, Row } from "react-bootstrap";

import "./steps.scss";

export const Steps = () => {
  return (
    <div className="pp-steps">
      <Container>
        <Row>
          <Col xs={12} md={4} className="step-col p-0">
            <div
              className="image"
              style={{
                backgroundImage: `url("./images/sections/steps/step-1.jpg")`,
              }}
            ></div>
            <div className="step-no">STEP 1</div>
            <div className="copy">
              Students ask for help via the Dock. It sends a request to his
              favourite tutor.
            </div>
          </Col>
          <Col xs={12} md={4} className="step-col p-0">
            <div
              className="image"
              style={{
                backgroundImage: `url("./images/sections/steps/step-2.jpg")`,
              }}
            ></div>
            <div className="step-no">STEP 1</div>
            <div className="copy">
              Tutors can spot all requests from the online dashboard. With one
              click, it starts the conversation with the student.
            </div>
          </Col>
          <Col xs={12} md={4} className="step-col p-0">
            <div
              className="image"
              style={{
                backgroundImage: `url("./images/sections/steps/step-3.jpg")`,
              }}
            ></div>
            <div className="step-no">STEP 1</div>
            <div className="copy">
              Tutor’s voice is picked up and played live through the Dock.
              Written explanation can be send to the kid tablet.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
