import { Container, Row, Col } from "react-bootstrap";
import { CTAs } from "../components/ctas";

import "./titleWithCTA.scss";

export const TitleWithCTA = (props) => {
  return (
    <div className="pp-title-with-cta">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col
            xs={12}
            className="title-col"
            style={{ maxWidth: props.title.maxWidth }}
          >
            <h2>{props.title.text}</h2>
          </Col>

          <Col xs={12} className="cta-col">
            <CTAs
              callBack={props.callBack}
              cta={{
                type: props.cta.type,
                text: props.cta.text,
                classes: props.cta.classes,
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
