import { Col, Container, Row } from "react-bootstrap";
import "./footer.scss";

export const Footer = (props) => {
  const year = new Date().getFullYear();

  return (
    <div className="pp-footer">
      <Container className="footer-container">
        <div
          className="holders-image"
          style={{
            backgroundImage: `url("./images/common/footer_holders.png")`,
          }}
        >
          &nbsp;
        </div>
      </Container>
      <div className="copyright">
        <Container>
          <Row>
            <Col className="d-flex justify-content-between">
              <span>All rights reserved ©{year}</span>
              <span className={"menu-items"}>
                <a className="why-penpal" href="#withPenpal">
                  Why PenPal
                </a>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
