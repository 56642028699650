import { Container, Row, Col } from "react-bootstrap";
import "./hero.scss";
import { useState } from "react";
import { PPButton } from "../components/button";

export const Hero = (props) => {
  const [path] = useState("./images/hero/hero_bg_desktop.png");

  return (
    <div className="pp-hero d-flex align-items-center">
      <div style={{ backgroundImage: `url(${path})` }} className="bg-image">
        &nbsp;
      </div>
      <Container>
        <Row>
          <Col
            offset={6}
            xs={12}
            md={{ span: 6, offset: 6 }}
            className={"text-col"}
          >
            <h1 className="hero-text">PENPAL</h1>
            <h2 className="hero-desc">Next Generation home tutoring</h2>

            <div className="buttons">
              <h3>Supercharge your kids homework</h3>

              <div className="buttons-wrapper">
                <PPButton
                  className="sign-up small"
                  onClick={() => props.enableModal("header")}
                  text="Pre-Order"
                />
                <PPButton
                  className="watch-video small"
                  onClick={() => props.enableModal("video")}
                  text="Watch Video"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
