import "./App.scss";
import "./styles/_fonts.css";

import { ThemeProvider } from "react-bootstrap";
import { Hero } from "./sections/hero";
import { Header } from "./sections/header";
import { Title } from "./sections/title";
import { EmptySpace } from "./sections/emptySpace";
import { TextImage } from "./sections/textImage";
import { Footer } from "./sections/footer";
import { PPModal } from "./sections/modal";
import { useState } from "react";
import { Quotes } from "./sections/quotes";
import { FullWidthImage } from "./sections/fullWidthImage";
import { CenterImageTwoCol } from "./sections/centerImageTwoCol";
import { TextOnImage } from "./sections/textOnImage";
import { TitleWithCTA } from "./sections/titleWithCTA";
import { Steps } from "./sections/steps";
import { WhyPenpal } from "./sections/whyPenpal";
import { FAQ } from "./sections/faq";
import { CtaWithArrow } from "./sections/ctaWithArrow";
import { GetStarted } from "./sections/getStarted";

function App() {
  const [modalState, setModalState] = useState({ active: false, type: "" });

  const enableModal = (type) => {
    setModalState({ active: true, type });
  };

  const disableModal = () => {
    setModalState({ active: false, type: "" });
  };

  return (
    <div className={`pp-app ${modalState.active ? "noscroll" : ""}`}>
      <ThemeProvider>
        <Header enableModal={enableModal} />

        <Hero enableModal={enableModal} />

        <Quotes />

        <Title
          text={
            "Imagine… Having direct access to a teacher, at the touch of a button."
          }
          id="withPenpal"
        />

        <FullWidthImage
          imagePath={"./images/sections/full-width-image/handy.png"}
        />

        <CenterImageTwoCol />

        <TextOnImage />

        <EmptySpace space={"50px"} />

        <TitleWithCTA
          title={{
            text: "If they are stuck, they can call for help",
            maxWidth: "410px",
          }}
          cta={{
            type: "video",
            text: "See How it works",
          }}
          callBack={() => {
            enableModal("video");
          }}
        />

        <EmptySpace space={"50px"} />

        <TextImage
          direction="ltr"
          overflow={{ top: 100, height: 125 }}
          colorTheme="green"
          imagePath="./images/sections/text-image/exercise"
          title="All exercises in a non distracting e-ink tablet"
          body="Developed with teachers and textbook publishers, Penpal is expert approved and brings the world’s education apps together into one easy-to-use, portable format."
        />

        <EmptySpace space={"50px"} />

        <TitleWithCTA
          title={{
            text: "Looking for learning platforms and education partners",
            maxWidth: "585px",
          }}
          cta={{
            type: "text",
            text: "GET IN TOUCH – NOW",
            classes: "btn-cta-green",
          }}
          callBack={() => {
            enableModal("header");
          }}
        />

        <FullWidthImage
          imagePath={"./images/sections/full-width-image/learning.jpg"}
        />

        <EmptySpace space={"150px"} />

        <TextImage
          direction="ltr"
          colorTheme="grey"
          imagePath="./images/sections/text-image/platform"
          highlight="Tutors and Teachers"
          title="A platform for educators too !"
          body="From public school teachers to private tutors, they all have a clear overview of how students are progressing. And with just a click of a button can communicate directly with them."
        />

        <EmptySpace space={"50px"} />

        <Steps />

        <EmptySpace space={"50px"} />

        <CtaWithArrow
          callBack={() => {
            enableModal("header");
          }}
        />

        <EmptySpace space={"150px"} />

        <TextImage
          direction="rtl"
          colorTheme="green"
          overflow={{ top: 20, height: 100 }}
          imageOverlap={200}
          imagePath="./images/sections/text-image/teacher"
          title="One teacher, multiple students"
          body="With Penpal, educators can monitor and support students when they need it. It’s on-demand tutoring, for a much lower cost."
        />

        <EmptySpace space={"150px"} mobileSpace={"50px"} />

        <WhyPenpal />

        <EmptySpace space={"50px"} />

        <GetStarted
          callBack={() => {
            enableModal("header");
          }}
        />

        <EmptySpace space={"150px"} />

        <FAQ />

        <EmptySpace space={"150px"} />

        <Footer enableModal={enableModal} />
      </ThemeProvider>

      {modalState.active && (
        <PPModal type={modalState.type} disableModal={disableModal} />
      )}
    </div>
  );
}

export default App;
