import { Col, Container, Row } from "react-bootstrap";
import "./ctaWithArrow.scss";
import { CTAs } from "../components/ctas";
export const CtaWithArrow = (props) => {
  return (
    <div className="pp-cta-with-arrow">
      <Container>
        <Row className="row d-flex align-items-center">
          <Col xs={12} xl={4} className="text-col">
            <div className="highlight">Calling for all levels</div>
            <div className="heading">
              <h2>Wanna become a tutor ?</h2>
            </div>
            <div className="copy">
              Side gig’ or full time work, Penpal is looking for qualified
              teacher to mentor learning session.
            </div>
          </Col>
          <Col xs={12} xl={4} className="arrow-col">
            <img src="./images/sections/cta-with-arrow/arrow.png" alt="" />
          </Col>
          <Col xs={12} xl={4} className="cta-col p-0">
            <CTAs
              callBack={props.callBack}
              cta={{
                type: "text",
                text: "LEARN MORE",
                classes: "btn-cta-green",
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
