import { Container, Row, Col } from "react-bootstrap";

import "./center-image-two-col.scss";

export const CenterImageTwoCol = () => {
  return (
    <div className="pp-center-image-two-col">
      <Container>
        <Row
          className="d-flex justify-content-center"
          style={{ backgroundImage: `url('./images/common/holder.png')` }}
        >
          <Col xs={12} lg={"auto"} className="text-col left">
            <h3>Machine Learning</h3>
            <p>
              On-dock Machine Learning get to know when your kid is most
              efficient. Penpal Dock rings and flashes when it’s time to start a
              session.
            </p>
          </Col>
          <Col className="text-col spacer">&nbsp;</Col>

          <Col xs={12} lg={"auto"} className="text-col right">
            <h3>Unobtrusive</h3>
            <p>
              As soon as the kid picks the pen up, his state is set to
              “studying”. Automatically notifying the platform about potential
              upcoming request.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
