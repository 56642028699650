import { Container, Row, Col } from "react-bootstrap";
import "./quotes.scss";

export const Quotes = () => {
  return (
    <div className="pp-quotes d-flex align-items-center">
      <Container>
        <Row>
          <Col xs={12} className="title-col">
            <h2>Praised by our early testers</h2>
          </Col>

          <Col xs={12} md={{ span: 5, offset: 1 }} className="quote-col">
            <div className="picture">
              <img src={"./images/sections/quotes/benjamin.png"} alt="" />
            </div>
            <div className="text">
              <div className="name">Benjamin</div>
              <div className="quote">
                “It’s really fun when my docks rings, makes me want to do go to
                it”
              </div>
            </div>
          </Col>

          <Col xs={12} md={{ span: 5, offset: 1 }} className="quote-col">
            <div className="picture">
              <img src={"./images/sections/quotes/mira.png"} alt="" />
            </div>
            <div className="text">
              <div className="name">Mira</div>
              <div className="quote">
                “I love hearing so explanation from the dock, it’s like a my
                teacher”
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
