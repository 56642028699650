import { Container } from "react-bootstrap";
import { CTAs } from "../components/ctas";
import "./getStarted.scss";

export const GetStarted = (props) => {
  return (
    <div className="pp-get-started">
      <Container>
        <div className="get-started-col basic">
          <div className="content-wrapper">
            <div className="title">
              <div className="highlight"></div>
              <div className="title">Basic Pack</div>
            </div>
            <div className="image">
              <img src="./images/sections/get-started/basic-pack.png" alt="" />
            </div>
            <div className="pricing">
              <div className="desc">Dock + eInk Tablet</div>
              <div className="price">99€</div>
              <div className="cta">
                <CTAs
                  callBack={props.callBack}
                  cta={{
                    type: "text",
                    text: "Pre Order",
                    classes: "get-started-cta btn-cta-yellow",
                  }}
                />
              </div>
            </div>
            <div className="details">
              <div className="detail">
                <div className="icon">
                  <img
                    src="./images/sections/get-started/icon-tutoring.png"
                    alt=""
                  />
                </div>
                <div className="text">Tutoring: 90€/month</div>
              </div>
              <div className="detail">
                <div className="icon">
                  <img
                    src="./images/sections/get-started/icon-shipping.png"
                    alt=""
                  />
                </div>
                <div className="text">Free Shipping to E.U.</div>
              </div>
              <div className="detail">
                <div className="icon">
                  <img
                    src="./images/sections/get-started/icon-dollar.png"
                    alt=""
                  />
                </div>
                <div className="text">All taxes included</div>
              </div>
            </div>
          </div>
        </div>

        <div className="get-started-col premium">
          <div className="content-wrapper">
            <div className="title">
              <div className="highlight">Early Bird</div>
              <div className="title">Premium Pack</div>
            </div>
            <div className="image">
              <img
                src="./images/sections/get-started/premium-pack.png"
                alt=""
              />
            </div>
            <div className="pricing">
              <div className="desc">Dock + eInk Tablet + Tutoring</div>
              <div className="price">
                499€
                <span className="crossed">1179€</span>
              </div>
              <div className="cta">
                <CTAs
                  callBack={props.callBack}
                  cta={{
                    type: "text",
                    text: "Pre Order",
                    classes: "get-started-cta btn-cta-yellow",
                  }}
                />
              </div>
            </div>
            <div className="details">
              <div className="detail">
                <div className="icon">
                  <img
                    src="./images/sections/get-started/icon-tutoring.png"
                    alt=""
                  />
                </div>
                <div className="text">One year access to a tutor</div>
              </div>
              <div className="detail">
                <div className="icon">
                  <img
                    src="./images/sections/get-started/icon-shipping.png"
                    alt=""
                  />
                </div>
                <div className="text">Free Shipping to E.U.</div>
              </div>
              <div className="detail">
                <div className="icon">
                  <img
                    src="./images/sections/get-started/icon-dollar.png"
                    alt=""
                  />
                </div>
                <div className="text">All taxes included</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
